<template>
  <v-dialog v-model="open" persistent width="600">
    <v-card>
      <v-card-title>{{ isNew ? "新增" : "編輯" }}線上一番賞</v-card-title>
      <v-card-text>
        <div class="mb-3">
          <!-- <v-row>
            <v-col class="mb-1 pb-1">
              <v-checkbox
                class="mt-0 pt-0"
                label="線上一番賞 (若已抽則不可切換)"
                v-model="ichiban.isOnline"
                hide-details
                :disabled="ichiban.sold > 0"
              ></v-checkbox>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6">
              <v-select
                label="店點"
                v-model="ichiban.branch"
                :items="branches"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="編號"
                outlined
                dense
                hide-details
                v-model="ichiban.key"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <!-- <div class="mb-3">
          <v-autocomplete
            label="格主"
            outlined
            dense
            hide-details
            v-model="ichiban.userID"
            :items="ownerOptions"
          ></v-autocomplete>
        </div> -->
        <div class="mb-3">
          <v-text-field
            label="名稱"
            outlined
            dense
            hide-details
            v-model="ichiban.name"
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="總抽數"
                outlined
                dense
                hide-details
                v-model.number="ichiban.count"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="已抽"
                outlined
                dense
                hide-details
                disabled
                :value="ichiban.sold"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="已中"
                outlined
                dense
                hide-details
                disabled
                :value="ichiban.win"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model.number="ichiban.consolationPrize"
                :disabled="ichiban.sold > 0"
                label="安慰獎點數"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="my-6"></v-divider>
        <v-alert v-if="ichiban.isOnline" type="warning" outlined>
          注意，僅能有抽數 1 的方案。
        </v-alert>
        <v-row
          class="my-0"
          v-for="(plan, i) of ichiban.plans"
          :key="'plan' + i"
          align="center"
          justify="center"
        >
          <v-col cols="4" class="pb-0">
            <v-text-field
              label="抽數"
              outlined
              dense
              hide-details
              disabled
              v-model.number="plan.count"
            ></v-text-field>
          </v-col>
          <v-col cols="7" class="pb-0">
            <v-text-field
              label="金額"
              outlined
              dense
              hide-details
              v-model.number="plan.amount"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="1" class="pb-0">
            <v-icon
              color="error"
              class="pointer"
              @click="removeItem('plans', i)"
              >mdi-close</v-icon
            >
          </v-col> -->
        </v-row>
        <!-- <div class="text-center mt-3 mb-3">
          <v-btn block color="primary" @click="addItem('plans')">
            新增價格方案
          </v-btn>
        </div> -->
        <v-divider class="my-6"></v-divider>
        <v-alert v-if="ichiban.isOnline" type="warning" outlined dense>
          注意，中獎號碼僅能為單一數字。
        </v-alert>
        <v-row
          class="my-0"
          v-for="(reward, i) of ichiban.rewards"
          :key="'reward' + i"
          align="center"
          justify="center"
        >
          <v-col cols="4" class="pb-0">
            <v-text-field
              label="號碼"
              outlined
              dense
              hide-details
              v-model="reward.number"
            ></v-text-field>
          </v-col>
          <v-col cols="7" class="pb-0">
            <v-text-field
              label="獎項"
              outlined
              dense
              hide-details
              v-model="reward.reward"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="pb-0">
            <v-icon
              color="error"
              class="pointer"
              @click="removeItem('rewards', i)"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
        <div class="text-center mt-3 mb-3">
          <v-btn block color="success" @click="addItem('rewards')">
            新增中獎號碼
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "DialogIchiban",
  props: ["value", "item", "branches", "userID"],
  data: () => ({
    ichiban: {
      userID: null,
      key: null,
      name: null,
      count: null,
      sold: 0,
      amount: 0,
      win: 0,
      rewards: [],
      plans: [
        {
          count: 1,
          amount: 0,
        },
      ],
      isOnline: true,
      approvalStatus: "pending",
    },
  }),
  created() {
    if (this.item) {
      this.ichiban = _.cloneDeep(this.item);
    }
    this.ichiban.userID = this.userID;
  },
  computed: {
    isNew() {
      return !this.item || !this.item._id;
    },
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    // mapUser() {
    //   return _.keyBy(this.users, "userID");
    // },
    // ownerOptions() {
    //   return this.owners.map((owner) => {
    //     const user = this.mapUser[owner.userID];
    //     return {
    //       text: `${owner.userID}（姓名: ${user.name || ""}, 電話: ${
    //         user.phone || ""
    //       }）`,
    //       value: owner.userID,
    //     };
    //   });
    // },
  },
  methods: {
    addItem(type) {
      let obj;
      switch (type) {
        case "plans":
          obj = {
            count: null,
            amount: null,
          };
          break;
        case "rewards":
          obj = {
            number: null,
            reward: null,
          };
          break;
      }
      this.ichiban[type].push(obj);
    },
    removeItem(type, n) {
      this.ichiban[type].splice(n, 1);
    },
    async confirm() {
      if (
        !this.ichiban.branch ||
        !this.ichiban.key ||
        !this.ichiban.name ||
        this.count === null ||
        this.count === ""
      ) {
        this.$toast.error("請檢查店點、編號、名稱、總抽數欄位！");
        return;
      }
      if (this.ichiban.rewards.length === 0) {
        this.$toast.error("沒有獎項不得上套");
        return;
      }
      if (this.ichiban.plans.length === 0) {
        this.$toast.error("沒有價格方案不得上套");
        return;
      }

      if (
        !this.ichiban.consolationPrize &&
        this.ichiban.consolationPrize !== 0
      ) {
        this.$toast.error("線上一番賞請填寫安慰獎點數！");
        return;
      }

      try {
        if (this.isNew) {
          await this.axios.post(`/ichiban/client-create`, this.ichiban);
          this.$toast.success("新增成功！");
        } else {
          let _id = this.ichiban._id;
          delete this.ichiban._id;
          await this.axios.put(`/ichiban/${_id}`, {
            ...this.ichiban,
          });
          this.$toast.success("編輯成功！");
        }
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error("新增失敗，請檢查編號是否重複！");
      }
    },
  },
};
</script>

<style></style>
