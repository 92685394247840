<template>
  <v-container fluid>
    <h3 class="page-title">格主一番賞管理</h3>
    <v-row>
      <v-col cols="auto" class="ml-auto">
        <v-btn
          color="primary"
          dense
          hide-details
          @click="addRecord"
          :disabled="!isOwner"
        >
          新增
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="6" sm="3" md="2">
        <v-select
          label="店點"
          v-model="branch"
          :items="branches"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col> -->
      <v-col cols="6" sm="3" md="2">
        <v-select
          label="上下架狀態"
          v-model="isAvailable"
          :items="[
            { text: '全部', value: null },
            { text: '上架', value: true },
            { text: '下架', value: false },
          ]"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-select
          label="銷售狀態"
          v-model="isSoldout"
          :items="[
            { text: '全部', value: null },
            { text: '完售｜出獎', value: true },
            { text: '未完售', value: false },
          ]"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-select
          label="審核狀態"
          v-model="approvalStatus"
          :items="[
            { text: '全部', value: null },
            { text: '待審核', value: 'pending' },
            { text: '通過', value: 'approved' },
            { text: '取消', value: 'canceled' },
          ]"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30],
          }"
        >
          <template v-slot:item.isAvailable="{ item }">
            <v-checkbox
              class="empty-label"
              v-model="item.isAvailable"
              @change="update(item, 'isAvailable')"
              disabled
            ></v-checkbox>
          </template>
          <template v-slot:item.approvalStatus="{ item }">
            {{ approvalStatusMap[item.approvalStatus] }}
          </template>
          <template v-slot:item.status="{ item }">
            <span
              v-if="
                item.sold === item.count ||
                item.rewards.every((item) => item.hit)
              "
              class="red--text"
              >完售
            </span>
          </template>
          <template v-slot:item.plans="{ item }">
            <div v-for="plan of item.plans">
              {{ plan.count }}抽 - ${{ plan.amount }}
            </div>
          </template>
          <template v-slot:item.rewards="{ item }">
            <div v-for="reward of item.rewards">
              <span :class="{ 'red--text': reward.hit }"
                >#{{ reward.number }} - {{ reward.reward }}</span
              >
            </div>
          </template>
          <template v-slot:item.sold="{ item }">
            <div :class="{ 'red--text': item.sold === item.count }">
              {{ item.sold }} / ${{ item.amount }}
            </div>
          </template>
          <template v-slot:item.update="{ item }">
            <v-btn
              text
              color="primary"
              @click="openDialog(item, 'ichiban')"
              :disabled="item.approvalStatus === 'approved'"
            >
              編輯
            </v-btn>
          </template>
          <template v-slot:item.display="{ item }">
            <v-btn
              text
              color="primary"
              @click="openDialog(item, 'display')"
              :disabled="item.approvalStatus === 'approved'"
            >
              展示圖
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogIchiban
      v-if="dialog.ichiban"
      v-model="dialog.ichiban"
      :item="selectedItem"
      @load="load"
      :branches="branches"
      :userID="userID"
    ></DialogIchiban>
    <DialogIchibanDisplay
      v-if="dialog.display"
      v-model="dialog.display"
      :item="selectedItem"
      @load="load"
      :branches="branches"
    ></DialogIchibanDisplay>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import login from "@/mixins/login";
import util from "@/mixins/util.vue";
import DialogIchiban from "@/components/client/DialogIchiban.vue";
import DialogIchibanDisplay from "@/components/dashboard/ichiban/DialogIchibanDisplay.vue";
import liff from "@line/liff";

export default {
  name: "Ichiban",
  mixins: [util, login],
  components: {
    DialogIchiban,
    DialogIchibanDisplay,
    // DialogIchibanOwner,
  },
  data: () => ({
    isOwner: false,
    data: [],
    owners: [],
    users: [],
    branch: null,
    isOnline: null,
    isAvailable: null,
    isSoldout: false,
    approvalStatus: null,
    approvalStatusMap: {
      pending: "待審核",
      approved: "通過",
      canceled: "取消",
    },
    dialog: {
      ichiban: false,
      display: false,
      owner: false,
    },
    // selectedItem: null,
    selectedItemId: "",
    uploadImageItem: null,
    keyword: "",
    headers: [
      {
        text: "審核狀態",
        value: "approvalStatus",
        align: "center",
        sortable: false,
      },
      {
        text: "上架",
        value: "isAvailable",
        align: "center",
        sortable: false,
        width: 80,
      },
      // {
      //   text: "線上",
      //   value: "isOnline",
      //   align: "center",
      //   sortable: true,
      //   width: 80,
      // },
      {
        text: "完售",
        value: "status",
        align: "center",
        sortable: false,
      },
      // { text: "店點", value: "branch", align: "left", sortable: false },
      { text: "編號", value: "key", align: "left", sortable: false },
      { text: "名稱", value: "name", align: "left", sortable: false },
      { text: "抽數", value: "count", align: "center", sortable: true },
      { text: "已抽", value: "sold", align: "center", sortable: true },
      { text: "中獎數", value: "win", align: "center", sortable: true },
      { text: "價格", value: "plans", align: "left", sortable: true },
      { text: "獎項", value: "rewards", align: "left", sortable: false },
      {
        text: "安慰獎",
        value: "consolationPrize",
        align: "left",
        sortable: false,
      },
      { text: "", value: "update", align: "center", sortable: false },
      { text: "", value: "display", align: "center", sortable: false },
    ],
    branches: [
      { text: "文賢店", value: "文賢" },
      { text: "奇美店", value: "奇美" },
      // { text: "崇善店", value: "崇善" },
      // { text: "友愛店", value: "友愛" },
    ],
  }),
  computed: {
    filterData() {
      let data = this.data;
      if (this.isAvailable !== null) {
        if (this.isAvailable) {
          data = data.filter((item) => item.isAvailable);
        } else {
          data = data.filter((item) => !item.isAvailable);
        }
      }
      if (this.approvalStatus !== null) {
        data = data.filter(
          (item) => item.approvalStatus === this.approvalStatus
        );
      }
      if (this.isSoldout !== null) {
        if (this.isSoldout) {
          data = data.filter(
            (item) =>
              item.count === item.sold ||
              item.rewards.every((reward) => !!reward.hit)
          );
        } else {
          data = data.filter(
            (item) =>
              item.count !== item.sold ||
              item.rewards.some((reward) => !reward.hit)
          );
        }
      }
      return data;
    },
    mapUser() {
      return _.keyBy(this.users, "userID");
    },
    selectedItem() {
      return this.data.find((item) => item._id === this.selectedItemId);
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.ready = true;
    //   this.load();
    // });
  },
  methods: {
    async addRecord() {
      // this.selectedItem = null;
      this.selectedItemId = "";

      this.dialog.ichiban = true;
    },
    async load() {
      const { data } = await this.axios.get(`/ichiban`, {
        params: { userID: this.userID },
      });
      this.data = data.reverse();
    },
    async getOwnerState() {
      try {
        const { data } = await this.axios.get(`/ichibanOwner/${this.userID}`);
        if (data._id) {
          this.isOwner = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async update(item, key) {
      await this.axios.put(`/ichiban/${item._id}`, {
        [key]: item[key],
      });
    },
    async openDialog(item, type) {
      // this.selectedItem = item;
      this.selectedItemId = item._id;
      this.dialog[type] = true;
    },
  },
  watch: {
    async userID(val) {
      if (!val) return;
      await this.getOwnerState();
      await this.load();

      if (!this.isOwner) {
        this.$dialog.info({
          dismissible: false,
          persistent: true,
          showClose: false,
          text: "請聯絡官方客服申請成為格主",
          actions: {
            true: {
              text: "確認",
              color: "primary",
              persistent: true,
              handler: () => liff.closeWindow(),
            },
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.empty-label {
  position: relative;

  ::v-deep .v-input__slot {
    justify-content: center;
  }
}
.typeWidth {
  width: 140px;
}
</style>
